<template>
  <VerticalEntity>
    <template v-slot:entity>
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body d-flex flex-center flex-column p-9">
          <!--begin::Avatar-->
          <div class="symbol symbol-65px symbol-circle mb-5">
            <img :src="user.avatar" alt="image">

          </div>
          <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0">{{user.fullName}}</a>
          <div class="fw-bold text-gray-400 mb-6">{{user.name}}</div>
          <Row :row="[{name:'Next Summary', value :user.nextSummary, type:'DATETIME'}]" />
        </div>

        <!--begin::Card body-->
      </div>
    </template>
    <template v-slot:tab>
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
        >
          <!--begin::Nav item-->
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              to="/profile/settings"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Profile details
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/profile/change-password"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Change Password
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/profile/notifications"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Notifications
            </router-link>
          </li>
          <!--end::Nav item-->
        </ul>
      </div>
    </template>
    <template v-slot:content>
      <router-view></router-view>
    </template>
  </VerticalEntity>

</template>

<script>
import {computed, onMounted} from "vue";

import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {useStore} from "vuex";
import VerticalEntity from "@/components/common/VerticalEntity";
import Row from "@/components/base/common/Row";

export default {
  name: "Profile",
  components: {Row, VerticalEntity},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("My Profile",
        [
          {link: false, router: '', text: 'My Profile'},
        ]
      )
    });
    const store = useStore();
    const user = computed(() => store.getters.currentUser);
    return {
      user,
    }
  }
}
</script>

<style scoped>

</style>