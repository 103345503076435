import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-lg-row" }
const _hoisted_2 = { class: "flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5" }
const _hoisted_3 = { class: "flex-lg-row-fluid" }
const _hoisted_4 = { class: "d-flex flex-wrap flex-stack pb-3" }
const _hoisted_5 = { class: "d-flex flex-wrap align-items-center my-1" }
const _hoisted_6 = { class: "tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "entity")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "tab")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "content")
      ])
    ])
  ]))
}